.endpoint__container {
    margin-top: 40px;
    margin-bottom: 32px;
    border-radius: 5px;
    background: #1A1D23;
    border-radius: 5px;
    padding: 28px 24px;
    padding-right: 0;
}

.endpoint__left {
    text-align: left;
    display: flex;
    flex-direction: column;
    z-index: 1;
}

.endpoint__herotext {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
}

.endpoint__subtext {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-top: 8px;
    color: #CAC8C8;
    letter-spacing: -0.04em;
}

.endpoint__api_pg {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 25px 0;
}

.endpoint__apilink {
    padding: 9px 0;
    padding-left: 11px;
    background-color: rgba(255, 255, 255, 0.19);
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-betweens;
}

.secret__keypart {
    display: flex;
    flex-direction: column;
}

.secret__keytext {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.04em;
    color: #CAC8C8;
}

.secret__keyvalue {
    display: flex;
    flex-direction: row;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.04em;
    margin-top: 4px;
    height: 16px;
    align-items: center;
}

.secret__copy {
    margin-left: 8px;
    cursor: pointer;
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.secret__tick {
    margin-left: 5px;
    color: rgb(0, 255, 0);
    cursor: default;
    display: flex;
    justify-content: center;
    align-items: center;
}

.endpoint__copy {
    margin-left: 12px;
    margin-right: 16px;
    background-color: rgba(255, 255, 255, 0);
    cursor: pointer;
}

.endpoint__tick {
    margin-left: 12px;
    margin-right: 12px;
    color: rgb(0, 255, 0);
    cursor: default;
    display: flex;
    justify-content: center;
    align-items: center;
}

.visibility__icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.hasura__secret_text {
    font-size: 13px;
    font-style: italic;
    color: rgba(255, 255, 255, 0.4);
}

.endpoint__pgbutton {
    margin-left: 12px;
    background-color: #ff7a00;
    color: white;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    padding: 12px 28px;
    border-radius: 81px;
}

.endpoint__pgbutton:hover {
    background-color: #b55700;
}

.endpoint__right {
    flex-grow: 1;
    margin-right: 20px;
}

.endright__container {
    border-left-color: rgb(255, 255, 255, 0.2);
    border-left-style: solid;
    padding-left: 24px;
    height: 100%;
    z-index: 1;
}

.endright__documentation {
    display: flex;
    flex-direction: row;
    margin-bottom: 28px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    cursor: pointer;
    color: #CAC8C8;
}

.endright__documentation:hover {
    color: white;
}

.endright__raiseticket {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    cursor: pointer;
    color: #CAC8C8;
}

.endright__raiseticket:hover {
    color: white;
}

.endright__svg {
    margin-right: 1rem;
}

@media screen and (max-width: 450px) {
    .endpoint__container {
        margin-top: 28px;
        padding-right: 28px;
        flex-direction: column;
        padding-bottom: 16;
        margin-bottom: 40px;
    }

    .endpoint__api_pg {
        flex-direction: column;
        align-items: flex-start;
        width: 335px;
        height: 60px;
    }

    .endpoint__pgbutton {
        margin-left: 0;
    }

    .endpoint_linkapi {
        width: 238px;
    }

    .endpoint__apilink {
        width: 100%;
        padding: 9px 12px;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        word-break: break-all;
    }

    .endright__container {
        border: none;
        padding-left: 0px;
    }

    .endpoint__right {
        margin-left: 0;
        margin-top: 30px;
    }

    .endpoint__copy {
        margin-right: 0;
        margin-left: 54px;
    }

    .endpoint__pgbutton {
        margin-top: 18px;
    }

    .right__container__links {
        margin-top: 70px;
        border-top: 1px solid rgba(255, 255, 255, 0.2);
        padding-top: 32px;
    }
}