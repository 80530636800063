@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Inter;
  src: url(./assets/Inter-VariableFont_slnt\,wght.ttf);
}

@font-face {
  font-family: Nevan;
  src: url(./assets/Nevan.ttf);
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0E1117;
  color: white;
  font-family: Inter;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* wallet adapter style overrides */

.wallet-adapter-button-trigger {
    background-color: #FF7A00 !important;
}

.wallet-adapter-button-trigger:hover {
    background-color: #B55700 !important;
}
