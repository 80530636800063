.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

button:disabled {
  opacity: 0.4;
}

.inner-right {
  width: calc(100% - 372px);
}

.portfolio-table .MuiLinearProgress-bar {
  background-color: #f15f0c;
}

#resources-menu .MuiMenu-paper {
  background: #1e1e1e !important;
}

#resources-menu .MuiMenu-paper .MuiMenu-list {
  min-width: 180px;
}

button.colored:hover {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.2)
    ),
    linear-gradient(269.68deg, #ffb800 -14.63%, #f55151 138.89%);
}

button.colored:active {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(269.68deg, #ffb800 -14.63%, #f55151 138.89%);
}

input[type="text"]:focus-visible {
  outline: none;
  border: 1px solid #e08e28 !important;
}
input[type="text"]::placeholder {
  color: #575757;
}

.MuiRadio-root.MuiRadio-colorPrimary {
  color: #e08e28 !important;
}

.web3 .w3ajs-social-logins,
.web3 .w3ajs-email-passwordless {
  display: none;
}

.social .w3ajs-external-wallet{
  display: none;
}