.metrics__usage {
    text-align: left;
    padding: 2rem 0;
}

.metrics__cont {
    display: flex;
    flex-direction: column;
    padding-right: 0rem;
}

.metrics__number {
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
}

.metrics__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
}

.metrics_padded {
    border-left-color: rgb(255, 255, 255, 0.2);
    border-left-style: solid;
}

@media screen and (max-width: 450px) {
    .metrics__usage {
        display: flex;
        flex-direction: column;
    }

    .metrics__line {
        padding-top: 24px;
        width: 80px;
    }

    .metrics_padded {
        border: none;
    }
}