.walletconnected__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: space-between; */
    flex-grow: 1;
}

.walletconnected__loader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
}