.metrics__container {
    margin-bottom: 0;
    justify-content: space-between;
    padding: 28px 24px;
    padding-right: 0;
    padding-bottom: 20px;
    background: linear-gradient(268.88deg, #FFB800 -42.97%, #F55151 161.87%);
    border-radius: 5px;
}

.metrics__left {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.metrics__herotext {
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    margin-bottom: 12px;
}

.metrics__plan {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 12px;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
}

.metrics__plantext {
    background-color: rgba(255, 255, 255, 0);
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
}

.metrics__planupgrade {
    margin-left: 20px;
    text-decoration: underline;
    background-color: rgba(255, 255, 255, 0);
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
}

.metrics__lastmonth {
    color: rgba(255, 255, 255, 0.386);
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
}

.metrics__right {
    opacity: 0.4;
}

@media screen and (max-width: 450px) {
    .metrics__container {
        padding: 28px 24px;
    }

    .metrics__herotext {
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: -0.04em;
    }

    .metrics__plan {
        padding-left: 16px;
        padding-right: 16px;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: -0.04em;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .metrics__plantext {
        width: 70%;
    }
}
