.project__container {
    align-items: baseline;
    margin-top: 70px;
    z-index: 1;
}

.project__name {
    text-align: left;
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    margin-right: 2rem;
}

.project__programid {
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #D9D9D9;
}

.project_linkid {
    text-decoration: underline;
}

@media screen and (max-width: 450px) {
    .project__programid {
        margin-top: 12px;
    }

    .project__container {
        padding-left: 28px;
    }
}