.notconnected__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    /* padding-top: 152px; */
}

.notconnected__midcont {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.notconnected__button {
    margin-top: 54px;
}

.notconnected__herotext {
    font-weight: 800;
    line-height: 80px;
}

.notconnected__experience {
    font-weight: 400;
    font-size: 30px;
    line-height: 80px;
}

.colored-grad {
    font-family: Nevan;
    background: linear-gradient(269.68deg, #FFB800 -14.63%, #F55151 138.89%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-weight: 700;
    font-size: 90px;
}

.notconnected__walletbutton {
    /* padding: 16px 32px !important; */
    width: 235px !important;
    height: 50px !important;
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: 22px !important;
    font-family: Inter !important;
    justify-content: center !important;

}

.notconnected__apply{
    background: rgba(255, 255, 255, 0.02);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 30px 40px;
    margin-bottom: 100px;
    margin-top: 100px;
}

.notconnected__apply__text {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
}

.notconnected__apply__button {
    margin-left: 28px;
    color: black;
    padding: 8px 28px;
    font-size: 12px;
    background: #FFFFFF;
    border: 1px solid white;
    border-radius: 81px;
}

.notconnected__apply__button:hover {
    background-color: transparent;
    color: white;
    border: 1px solid white;
}

@media screen and (max-width: 450px) {
    .notconnected__apply {
        margin-top: 80px;
    }

    .notconnected__apply__text {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
    }

    .notconnected__apply__button {
        margin-left: 0;
    }
}
