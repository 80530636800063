.notwhitelisted__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    padding: 5rem;
}

.notwhitelisted__subcont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
